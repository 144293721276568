<template>
  <ValidationProvider
    ref="fieldCfeCode"
    :name="$t(label)"
    :rules="required ? 'required' : ''"
    :vid="label"
    slim
  >
    <template #default="{ errors, valid }">
      <v-autocomplete
        v-model="cfeCodeId"
        :error-messages="errors"
        :items="items"
        :label="$t(label)"
        :menu-props="{ offsetY: true }"
        :success="valid"
        clearable
        dense
        hide-details="auto"
        item-text="code"
        item-value="id"
        outlined
        v-bind="$attrs"
        @change="onChange"
      >
        <template #item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-text="item.description" />
            <v-list-item-subtitle v-text="item.code" />
          </v-list-item-content>
        </template>

        <template #selection="{ item }">
          <v-list class="pa-0 flex-shrink-1" color="transparent" dense>
            <v-list-item :input-value="true">
              <v-list-item-content>
                <v-list-item-title v-text="item.code" />
                <v-list-item-subtitle v-text="item.description" />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-autocomplete>
    </template>
  </ValidationProvider>
</template>

<script lang="ts">
import CacheMixin from "@/mixins/CacheMixin";
import type { CfeCode } from "@planetadeleste/vue-mc-gw";
import { CfeCodeCollection } from "@planetadeleste/vue-mc-gw";
import { Component, Mixins, Prop, VModel } from "vue-property-decorator";
import { filter, map } from "lodash";
import { ConfigModule } from "@/store/config";

@Component
export default class CfeCodeSelect extends Mixins(CacheMixin) {
  @VModel({ type: [String, Number] }) cfeCodeId!: number;

  @Prop({ type: String, default: "cfecode" }) readonly label!: string;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly autoassign!: boolean;
  @Prop(Boolean) readonly getAll!: boolean;

  arItems: CfeCode[] = [];

  get companyCfeCodeListId(): number[] {
    return map(ConfigModule.companyCfeCodes, "id");
  }

  get cacheKey() {
    return "CfeCode";
  }

  get cacheIdentifier() {
    return "models";
  }

  get items(): CfeCode[] {
    return this.getAll
      ? this.arItems
      : filter(this.arItems, (obItem) =>
          this.companyCfeCodeListId.includes(obItem.id)
        );
  }

  mounted() {
    this.load();
  }

  async load() {
    const arItems: CfeCode[] | undefined = this.cache();
    if (arItems && arItems.length) {
      this.arItems = arItems;
      return;
    }

    const obCollection = new CfeCodeCollection();
    await obCollection.list();

    if (obCollection.length) {
      obCollection.sort("code");
      this.arItems = obCollection.models;
      this.cache(obCollection.models);
    }
  }

  onChange(iValue: number) {
    const obModel = this.$_.find(this.cache(), { id: iValue });
    this.$emit("change", obModel);
  }
}
</script>
