<template>
  <v-row>
    <v-col cols="12" lg="6">
      <account-code-select v-model="obAccountReceipt.code" required />
    </v-col>

    <v-col cols="12" lg="6">
      <cfe-codes-select
        v-model="obAccountReceipt.cfe_code_id"
        required
        @change="onSelectCfeCode"
      />
    </v-col>

    <v-col cols="12">
      <form-field-text v-model="obAccountReceipt.name" required />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, VModel, Vue } from "vue-property-decorator";
import type { CfeCodeData } from "@planetadeleste/vue-mc-gw";
import { AccountReceipt } from "@planetadeleste/vue-mc-gw";
import AccountCodeSelect from "@/modules/accounts/components/AccountCodeSelect.vue";
import CfeCodesSelect from "@/modules/cfecodes/components/CfeCodesSelect.vue";

@Component({
  components: { CfeCodesSelect, AccountCodeSelect },
})
export default class AccountreceiptsSettings extends Vue {
  @VModel({ type: Object, default: () => new AccountReceipt() })
  obAccountReceipt!: AccountReceipt;

  onSelectCfeCode(obItem: CfeCodeData) {
    if (obItem.description && !this.obAccountReceipt.name) {
      this.obAccountReceipt.set("name", obItem.description);
    }
  }
}
</script>
